import React from "react";

const Header = () => {
  return (
    <>
      <header id="header" className="header_space header_section sticky">
        <div className="header-main-container">
          <div>
            <a className="navbar-brand-2 py-0" href="index.html">
              <img src="/images/VTPaltitude-logo-1.webp" alt="logo" />
            </a>
          </div>
          <div className="header-btn-main">
            <ul class="header-btn-container">
              <li>
                <a href="tel:08065206017" class="header-btn custom-btn">
                  <i className="bi bi-telephone"></i>
                  &nbsp;08065206017
                </a>
              </li>
              <li>
                <a
                  className="header-btn custom-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#downloadbrochure"
                >
                  ENQUIRY NOW
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
