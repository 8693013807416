import React, { useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet';

const ThankYou = () => {
  const downloadBtn = useRef();
  const [thankyouMsg, setThankyouMsg] = useState({
    userName:'',
    Mobile:'',
    Email:'',
  })

  useEffect(() => {
    const msg = JSON.parse(localStorage.getItem("formData"));
    if(msg?.FirstName){
      setThankyouMsg({...thankyouMsg, 
        userName : msg.FirstName+' '+msg.LastName,
        Mobile : msg.CountryCode+' '+msg.Mobile,
        Email : msg.Email
      });
    }
    
    const downloadBrochure = localStorage.getItem("downloadBrochure");
    if(downloadBrochure === 'yes'){
      downloadBtn.current.click();
      localStorage.removeItem("downloadBrochure");
    }
  },[]);

  // useEffect(() => {
  //   const existingNoscript = document.querySelector('noscript[data-gtm]');

  //   if (!existingNoscript) {
  //     const noscript = document.createElement('noscript');
  //     noscript.setAttribute('data-gtm', 'GTM-PG5V5H6');
  //     const iframe = document.createElement('iframe');
  //     iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-PG5V5H6";
  //     iframe.height = "0";
  //     iframe.width = "0";
  //     iframe.style.display = "none";
  //     iframe.style.visibility = "hidden";
  //     noscript.appendChild(iframe);

  //     document.body.insertBefore(noscript, document.body.firstChild);
  //   }
  // }, []);

  return (
    <>
      {/*
        <Helmet>
          <title>Altitude - Thank You</title>
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-PG5V5H6');
            `}
          </script>
        </Helmet>
      */}
      <section id="thank-you-bg" className="bg-container">
        <div className="main-container">
          <div className="row justify-content-center align-items-center m-0">
            <div className="col-md-12 px-0">
              <div className="thankyou-logo">
                <img src="/images/thankyou-logo.webp" alt="Brand-Logo" />
              </div>
              <a style={{ opacity: '0', height: '0' }} href={`${process.env.REACT_APP_FRONTEND_URL}pdf/Altitude-E-Brochure.pdf`} ref={downloadBtn} download="Altitude-E-Brochure">Altitude-E-Brochure</a>
              <h1 className="text-white text-center mt-2">Thank You</h1>
              <div className="user-details">
                <p>Full Name: {thankyouMsg?.userName}</p>
                <p>Phone: {thankyouMsg?.Mobile}</p>
                <p>Email: {thankyouMsg?.Email}</p>
              </div>
              <div className="text-center">
                <span className="text-white">
                  Thank you for the enquiry. Our team will soon get in touch
                  with you. <br /> Altitude
                </span>
              </div>
              <nav className="home">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Thank You</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
