import React from "react";

const MobileContactBtn = () => {
  return (
    <>
      <div className="side_spacing privacy-policy-footer"></div>
      <div className="bottom-buttons sticky-button">
        <a className="call-now" href="tel:08065206017">
          Call Now
        </a>
        <a
          className="enquire-bottom"
          data-bs-toggle="modal"
          data-bs-target="#downloadbrochure"
        >
          Enquire Now
        </a>
      </div>
    </>
  );
};

export default MobileContactBtn;
